<template>
  <div>
    <div class="d-flex">
      <b-sidebar
        id="profile-sidebar-right"
        class="sidebar-access"
        right
        bg-variant="white"
        :width="device === 'desktop' ? '50vw' : '100vw'"
        v-model="isOpen"
        @hidden="closeModal"
      >
        <template #header>
          <div class="header-sidebar d-flex justify-content-between">
            <span class="sidebar-title">
              {{ profileSelected ? profileSelected.name : modalTitle }}
            </span>

            <span
              class="close-icon"
              @click="closeModal()"
            >
              <CloseIcon />
            </span>
          </div>
          <div class="separator"></div>
        </template>
        <template>
          <div class="access-block">
            <div class="header">
              <div class="text">{{ $t('ProfilesPage.Access') }}</div>
              <div class="type-check">
                <span v-if="verifyIfExist(accessList, 'show')">{{ $t('ProfilesPage.View') }}</span>
                <span v-if="verifyIfExist(accessList, 'update')">{{
                  $t('ProfilesPage.Edit')
                }}</span>
                <span v-if="verifyIfExist(accessList, 'create')">{{
                  $t('ProfilesPage.Create')
                }}</span>
                <span v-if="verifyIfExist(accessList, 'delete')">{{
                  $t('ProfilesPage.Erase')
                }}</span>
              </div>
            </div>
            <div class="body">
              <access-table
                v-if="forceRenderAccessTable"
                :list="accessList"
                :initialList="accessList"
                @allCheckedToFalse="allCheckedToFalse"
                @forceRenderListAccess="forceRenderListAccess"
                class="access-table"
              />
            </div>
          </div>
        </template>

        <template #footer>
          <div class="separator"></div>
          <b-col
            md="12"
            class="footer-sidebar"
          >
            <div :class="['d-flex', 'buttons']">
              <b-button
                :class="['btn-cancel', 'btn-footer']"
                @click="closeModal()"
              >
                {{ $t('RefuseStatus.Cancel') }}
              </b-button>
              <b-button
                class="btn-save btn-footer"
                @click="onSave()"
              >
                {{ $t('RefuseStatus.Save') }}
              </b-button>
            </div>
          </b-col>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BButton, BRow, BCol, BSidebar, BCollapse } from 'bootstrap-vue';
  import AccessTable from './AccessTable.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    data() {
      return {
        forceRenderAccessTable: true,
        isOpen: false,
        prefix: localStorage.getItem('prefix'),
        accessList: []
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      BCollapse,
      AccessTable,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      callPermissionsRoute: {
        type: Boolean,
        default: false
      },
      device: {
        type: String,
        default: 'desktop'
      },
      profileSelected: {
        type: Object,
        default: null
      }
    },
    mounted() {
      this.getAccesList();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('profile', ['UPDATE_MODAL_ACCESS_TOGGLE', 'UPDATE_PERMISSIONS_IDS']),
      closeModal() {
        this.UPDATE_MODAL_ACCESS_TOGGLE(false);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      allCheckedToFalse() {
        this.accessList.map((e) => {
          if (!!e.checked) e.checked = false;
        });
      },
      async getAccesList() {
        await this.$http2
          .get('/api/myconfig/profile/access/context')
          .then((response) => {
            this.accessList = response.data.data;

            this.populateOptionsIds();
          })
          .catch((error) => console.error(error));
      },
      async getPermissionsIds() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http2
          .get(`/api/myconfig/profile/${this.profileSelected.id}/permission`)
          .then((response) => {
            this.UPDATE_PERMISSIONS_IDS(response.data.data);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          })
          .catch((error) => console.error(error));
      },
      verifyIfExist(list, type) {
        let arr = [];
        list.forEach((e) => {
          if (e.children.length > 0) {
            arr.push(this.verifyIfExist(e.children, type));
          }
          arr.push(e.options.some((f) => f.type === type));
        });
        return arr.includes(true);
      },
      populateOptionsIds() {
        this.accessList.map((e) => {
          switch (e.name) {
            case 'mysfc':
              e['optionsIds'] = this.getOptionsIds(e.children.length ? e.children : e);
              break;
            case 'myconnect':
              e['optionsIds'] = this.getOptionsIds(e.children.length ? e.children : e);
              break;
            case 'myconfig':
              e['optionsIds'] = this.getOptionsIds(e.children.length ? e.children : e);
              break;

            default:
              break;
          }
        });
      },
      async onSave() {
        const myConfigOptionsIds = this.accessList.find((e) => e.name === 'myconfig')?.optionsIds;
        const hasCommonElement = this.permissions.some((item) => myConfigOptionsIds.includes(item));
        if (hasCommonElement) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          await this.$http2
            .get(`api/myconfig/profile/${this.profileSelected.id}/users/email`)
            .then((response) => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              if (response.data.data.usersWithoutEmail.length > 0) {
                this.$emit('openModalAttention', response.data.data);
              } else {
                this.routePostAccess();
              }
            })
            .catch((error) => console.error(error));
        } else {
          this.routePostAccess();
        }
      },
      async routePostAccess() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http2
          .put(`/api/myconfig/profile/${this.profileSelected.id}/permission`, this.permissions)
          .then(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.closeModal();
            this.$emit('updatePermissions');
          })
          .catch((error) =>
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
            })
          );
      },
      getOptionsIds(list) {
        let optionsIds = [];
        let newList = list;
        if (!list.length) {
          newList = [list];
        }

        newList.map((e) => {
          e.options.map((f) => {
            optionsIds.push(f.id);
          });
        });
        return optionsIds;
      },
      forceRenderListAccess() {
        this.forceRenderAccessTable = false;
        this.$nextTick().then(() => {
          this.forceRenderAccessTable = true;
        });
      }
    },
    computed: {
      ...mapState('profile', {
        isModalAccessOpen: 'isModalAccessOpen',
        permissions: 'permissions'
      })
    },
    watch: {
      isModalAccessOpen(v) {
        this.isOpen = v;
        if (v == true) {
          this.getPermissionsIds();
        }
      },
      callPermissionsRoute() {
        this.routePostAccess();
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-access {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .single-select {
            &.chip-responsible {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100% !important;
              border: none;

              .multiselect-invalid-feedback {
                margin-top: 3.5px;
              }

              .multiselect-component-single {
                .multiselect__tags {
                  height: auto !important;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  .multiselect__input {
                    margin-bottom: 10px;
                  }

                  .multiselect__placeholder {
                    color: #cfc4be;
                  }

                  .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;
                    height: auto !important;

                    .multiselect__tag-icon {
                      line-height: 21px;

                      &:hover {
                        background-color: #974900;
                        text-decoration: none;
                        opacity: 0.75;

                        &:after {
                          color: #000;
                        }
                      }

                      &:after {
                        color: #fff;
                      }
                    }
                  }
                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                }

                .multiselect__select {
                  height: 36px;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              &.focus {
                box-shadow: none;
              }
            }
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }

        .access-block {
          min-width: 500px;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            padding: 16px 16px 8px;

            .text {
              width: 100%;
              color: #998f8a;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
            }

            .type-check {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              gap: 10px;

              span {
                min-width: 54.75px;
                color: #4c4541;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
              }
            }
          }
          .body {
            padding: 0 8px;
            max-height: calc(100vh - 200px);
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-track {
              background: #fff;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #cfc4be;
              border-radius: 9px;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }

      @media (max-width: 1000px) {
        width: 100vw !important;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }

  .linked-items {
    display: flex;
    align-items: center;
    gap: 16px;
    .linked-items-text {
      color: #998f8a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      font-family: Raleway;
    }

    .icons-modal {
      display: flex;
      align-items: center;
      color: #974900;
      .icon-modal {
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        .icon-duplicate {
          width: 14px;
          height: 14px;
          fill: #974900 !important;
          svg {
            path {
              fill: #974900;
            }
          }
        }
      }
    }
  }
</style>
