<template>
  <div class="d-flex">
    <b-sidebar
      id="profile-sidebar-right"
      class="sidebar-add-profile"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '60vw' : '100vw'"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
        <div class="separator"></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual ? $t('ProfilesPage.EqualProfileName') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
              lazy-formatter
              :formatter="
                () => {
                  states.name = name !== '';
                  return name;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          v-if="type == 'duplicate'"
        >
          <div class="linked-items">
            <span class="linked-items-text">{{ $t('ProfilesPage.LinkedItems') }}:</span>
            <div class="icons-modal">
              <div class="icon-modal">
                <ProfileIcon class="icon-duplicate" />
                <span>{{ profileSelected.users_quantity }}</span>
              </div>
              <div class="icon-modal">
                <KeyIcon class="icon-duplicate" />
                <span>{{ profileSelected.securable_permission_quantity }}</span>
              </div>
            </div>
          </div>
        </b-col>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div :class="['d-flex', 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BButton, BRow, BCol, BSidebar, BCollapse } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  export default {
    data() {
      return {
        flagNameEqual: false,
        isOpen: false,
        states: {
          name: null
        },
        name: ''
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      BCollapse,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      ProfileIcon: () => import('@core/assets/icons/profile-icon-filled.svg'),
      KeyIcon: () => import('@core/assets/icons/key-icon-2.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      profileSelected: {
        type: Object,
        default: null
      },
      type: {
        type: String,
        default: ''
      }
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('profile', [
        'UPDATE_MODAL_EDIT_DELETE_TOGGLE',
        'UPDATE_TYPE_MODAL',
        'UPDATE_MODAL_LINK_PEOPLE_TOGGLE',
        'UPDATE_MODAL_PEOPLE_TOGGLE',
        'UPDATE_FIRST_CREATION_LIST'
      ]),
      closeModal() {
        this.name = '';
        this.UPDATE_TYPE_MODAL('');
        this.UPDATE_MODAL_EDIT_DELETE_TOGGLE(false);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      clearValidations() {
        this.states.name = null;
      },
      fieldsValidate() {
        this.clearValidations();

        const validName = (this.states.name =
          this.name && this.flagNameEqual === false ? true : false);

        return validName;
      },
      async onSave() {
        this.flagNameEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const payload = {
          name: this.name
        };

        if (this.typeModalStore === 'new') {
          await this.$http2
            .post(`/api/myconfig/profile`, payload)
            .then((response) => {
              const data = { name: this.name, id: response.data.data.id };
              this.UPDATE_MODAL_PEOPLE_TOGGLE(true);
              this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(true);
              this.UPDATE_FIRST_CREATION_LIST(true);
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('ProfilesPage.ProfileCreated'),
                'success'
              );
              this.$emit('updateList', data);
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }

        if (this.typeModalStore === 'edit') {
          await this.$http2
            .put(`/api/myconfig/profile/${this.profileSelected.id}`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('ProfilesPage.ChangesSaved'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }

        if (this.typeModalStore === 'duplicate') {
          await this.$http2
            .post(`/api/myconfig/profile/${this.profileSelected.id}/duplicate`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('ProfilesPage.ProfileCreated'),
                'success'
              );
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.id === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      }
    },
    computed: {
      ...mapState('profile', {
        isModalEditRenameDeleteOpen: 'isModalEditRenameDeleteOpen',
        typeModalStore: 'typeModalStore'
      })
    },
    watch: {
      isModalEditRenameDeleteOpen(v) {
        this.isOpen = v;
        this.clearValidations();
      },
      typeModalStore(v) {
        if (v === 'edit') {
          this.name = this.profileSelected.name;
        }
        if (v === 'duplicate') {
          this.name = `${this.profileSelected.name} (${this.$t(
            'ProfilesPage.DuplicateProfileName'
          )})`;
        }
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-add-profile {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .single-select {
            &.chip-responsible {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100% !important;
              border: none;

              .multiselect-invalid-feedback {
                margin-top: 3.5px;
              }

              .multiselect-component-single {
                .multiselect__tags {
                  height: auto !important;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  .multiselect__input {
                    margin-bottom: 10px;
                  }

                  .multiselect__placeholder {
                    color: #cfc4be;
                  }

                  .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;
                    height: auto !important;

                    .multiselect__tag-icon {
                      line-height: 21px;

                      &:hover {
                        background-color: #974900;
                        text-decoration: none;
                        opacity: 0.75;

                        &:after {
                          color: #000;
                        }
                      }

                      &:after {
                        color: #fff;
                      }
                    }
                  }
                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                }

                .multiselect__select {
                  height: 36px;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              &.focus {
                box-shadow: none;
              }
            }
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }
        .input-email,
        .input-phone {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-inline: 16px;
          gap: 16px;

          .form-group {
            width: inherit;
          }
        }

        .icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 0px;
          margin-top: 7px;
          max-width: 38px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #974900;

          &.btn-phone,
          &.btn-email {
            background-color: transparent !important;
            color: #974900 !important;
            border: 1px solid #974900 !important;

            &:hover {
              background-color: #ffede2 !important;
            }

            &:active {
              background-color: #ffdbc4 !important;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .step-action {
      padding: 16px 0;

      .step-collapse {
        padding-left: 16px;
      }

      .step-collapse,
      .text {
        display: flex;
        gap: 8px;
        color: #4c4541;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        .icon {
          svg {
            width: 16px;
            height: 16px;
            fill: #974900;
          }
        }

        &.not-collapsed {
          svg {
            transform: rotate(-180deg);
            transition: all 0.3s;
          }
        }

        &.collapsed {
          svg {
            transform: rotate(0);
            transition: all 0.3s;
          }
        }
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
      }

      .step-progress {
        display: flex;
        gap: 4px;
        padding: 4px 16px 0;
        .first-step,
        .second-step {
          width: 50%;
          height: 4px;
          border-radius: 4px;
          background: #f4eee8;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .progress-block {
      padding-top: 16px;

      .detail-block {
        padding: 16px 16px 0;

        .info-block,
        .login-block {
          display: flex;
          align-items: center;
          gap: 8px;
          .icon {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 7px;
            border-radius: 50%;
            border: 1px solid #cfc4be;

            svg {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }

          .text {
            overflow: hidden;
            color: #cfc4be;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
          }

          &.step-active {
            .icon {
              border-color: #974900;
              svg {
                fill: #974900;
              }
            }

            .text {
              color: #974900;
            }
          }

          &.next-step {
            .icon {
              border-color: #66bb6a;
              background: #66bb6a;
              svg {
                fill: #fff;
              }
            }

            .text {
              color: #66bb6a;
            }
          }
        }

        .vertical-separator {
          margin-left: 14px;
          width: 1px;
          height: 16px;
          background: #cfc4be;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .separator-progress {
      width: 100%;
      height: 1px;
      background: #eee;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }

  .linked-items {
    display: flex;
    align-items: center;
    gap: 16px;
    .linked-items-text {
      color: #998f8a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      font-family: Raleway;
    }

    .icons-modal {
      display: flex;
      align-items: center;
      color: #974900;
      .icon-modal {
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        .icon-duplicate {
          width: 14px;
          height: 14px;
          fill: #974900 !important;
          svg {
            path {
              fill: #974900;
            }
          }
        }
      }
    }
  }
</style>
