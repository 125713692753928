<template>
  <div>
    <b-sidebar
      id="structure-people-options-sidebar-right"
      class="structure-sidebar-options-modal"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-end">
          <span
            v-if="device !== 'mobile'"
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <div class="option-modal-body">
          <div class="unlink-block">
            <div
              @click="openUnlinkBlock"
              :class="['unlink-collapse', isUnlinkVisible ? 'not-collapsed' : 'collapsed']"
            >
              {{ $t('DepartmentPage.UnlinkPeople') }}
              <ArrowRight />
            </div>
            <b-collapse
              :visible="isUnlinkVisible"
              id="unlink-collapse"
            >
              <div class="text">
                {{ $t('ProfilesPage.UnlinkText') }}
              </div>
              <b-button
                @click="onUnlinkPeople()"
                class="unlink-button"
                >{{ $t('IntegrationPage.Unlink') }}</b-button
              >
            </b-collapse>
          </div>
          <div :class="['transfer-block', isTransferVisible ? 'transfer-open' : '']">
            <div
              @click="openTransferBlock"
              :class="['transfer-collapse', isTransferVisible ? 'not-collapsed' : 'collapsed']"
            >
              {{ $t('DepartmentPage.TransferPeople') }} <ArrowRight />
            </div>
            <b-collapse
              :visible="isTransferVisible"
              id="transfer-collapse"
            >
              <div class="text">
                {{ $t('ProfilesPage.TransferProfileText') }}
              </div>
              <div class="search-block">
                <div class="label-title">{{ $t('ProfilesPage.Profile') }}</div>
                <div class="div-custom-input">
                  <div class="icon-block">
                    <SearchIcon class="search-icon" />
                  </div>
                  <b-form-input
                    v-model="searchFilter"
                    class="search-input-link-people"
                    type="text"
                    :placeholder="$t('SearchPlaceholderDefault')"
                    @input="(e) => updateFilter(e)"
                  />
                </div>
              </div>
              <div class="profile-block">
                <div
                  v-if="filteredProfiles.length === 0"
                  class="block-no-results"
                >
                  <div class="no-results">
                    <SearchIcon />
                    <p class="text">{{ $t('NoResultsFound') }}</p>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="profile in filteredProfiles"
                    :key="profile.id"
                    class="item"
                  >
                    <input
                      v-model="selectedWorkCenter"
                      type="radio"
                      :name="`my-input-${profile.id}`"
                      :id="`profile-${profile.id}`"
                      :value="profile.id"
                    />
                    <span>{{ profile.name }}</span>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </template>
      <template #footer>
        <b-col
          v-if="isTransferVisible"
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex">
            <b-button
              class="btn-transfer btn-footer"
              @click="onTransferPeople()"
            >
              {{ $t('DepartmentPage.Transfer') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar, BButton, BCollapse, BFormInput, BCol } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: {
      BSidebar,
      BCollapse,
      BFormInput,
      BButton,
      BCol,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      ArrowRight: () => import('@core/assets/icons/arrow-right.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg')
    },
    props: {
      device: {
        type: String,
        default: 'desktop'
      },
      isToUnlink: {
        type: Boolean,
        default: false
      },
      isToTransfer: {
        type: Boolean,
        default: false
      },
      currentId: {
        type: Number,
        default: null
      },
      prefix: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false,
        isUnlinkVisible: false,
        isTransferVisible: false,
        profileList: [],
        filteredProfiles: [],
        searchFilter: '',
        selectedWorkCenter: 0
      };
    },
    mounted() {
      this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('profile', ['UPDATE_MODAL_OPTIONS_TOGGLE']),
      closeModal() {
        this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
        this.$emit('close');
      },
      openUnlinkBlock() {
        this.isUnlinkVisible = !this.isUnlinkVisible;
        this.isTransferVisible = false;
      },
      openTransferBlock() {
        this.isUnlinkVisible = false;
        this.isTransferVisible = !this.isTransferVisible;
      },
      updateFilter(e) {
        const filterValue = e.toLowerCase();
        const removedCurrentProfile = this.profileList.filter((e) => e.id !== this.currentId);
        const filteredArray = removedCurrentProfile.filter((item) => {
          return item.name.toLowerCase().includes(filterValue);
        });
        this.filteredProfiles = filteredArray;
      },
      async getProfiles() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http
          .get(`/api/${this.prefix}/myconfig/profile/context`)
          .then((response) => {
            let filteredArr = [];
            response.data.data.map((e) => {
              if (this.currentId !== e.id) filteredArr.push(e);
            });
            this.profileList = filteredArr;
            !this.isFirstCreation && this.UPDATE_FLAG_SHOW_OVERLAY(false);
          })
          .catch((error) => console.error(error));
      },
      onUnlinkPeople() {
        this.$emit('onUnlinkPeople');
      },
      onTransferPeople() {
        this.$emit('onTransferPeople', this.selectedWorkCenter);
      }
    },
    computed: {
      ...mapState('profile', {
        isModalOptionsPeopleOpen: 'isModalOptionsPeopleOpen',
        dragList: 'dragList',
        isFirstCreation: 'isFirstCreation'
      })
    },
    watch: {
      isModalOptionsPeopleOpen(v) {
        this.isOpen = v;
        this.selectedWorkCenter = 0;
        this.isTransferVisible = this.isToTransfer;
        this.isUnlinkVisible = this.isToUnlink;
        if (v) this.getProfiles();
      },
      isTransferVisible() {
        this.searchFilter = '';
      },
      profileList(v) {
        this.filteredProfiles = v;
      }
    }
  };
</script>
<style lang="scss">
  .structure-sidebar-options-modal {
    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      border-left: 1px solid #cfc4be;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
      @media (max-width: 480px) {
        width: 100vw !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        gap: 8px;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;

          @media (max-width: 480px) {
            font-size: 16px;
          }
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding-bottom: 16px;
        overflow: hidden;

        .option-modal-body {
          height: calc(100% - 70px);

          .unlink-block {
            padding: 16px;
            .unlink-collapse {
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #4c4541;
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;

              svg {
                width: 16px;
                height: 16px;
                fill: #974900;
              }

              &.not-collapsed {
                svg {
                  transform: rotate(90deg);
                  transition: all 0.3s;
                }
              }

              &.collapsed {
                svg {
                  transform: rotate(0);
                  transition: all 0.3s;
                }
              }
            }

            .text {
              color: #998f8a;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: normal;
              padding: 8px 0 16px;
            }

            .unlink-button {
              width: 100%;
              border-color: #d32f2f !important;
              background-color: #d32f2f !important;
              padding: 8px 21px !important;
              color: #fff;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.175px;
            }
          }

          .transfer-block {
            padding: 15px 16px;
            border-top: 1px solid #cfc4be;
            border-bottom: 1px solid #cfc4be;

            .collapse.show {
              &:has(.no-results) {
                height: 100%;
              }
            }

            .transfer-collapse {
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #4c4541;
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;

              svg {
                width: 16px;
                height: 16px;
                fill: #974900;
              }

              &.not-collapsed {
                svg {
                  transform: rotate(90deg);
                  transition: all 0.3s;
                }
              }

              &.collapsed {
                svg {
                  transform: rotate(0);
                  transition: all 0.3s;
                }
              }
            }

            .text {
              color: #998f8a;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: normal;
              padding: 8px 0 16px;
            }

            .search-block {
              .div-custom-input {
                display: flex;
                align-items: center;
                border-radius: 4px;
                width: 100%;
                overflow: hidden;
                border: 1px solid #cfc4be;
                .icon-block {
                  display: flex;
                  align-items: center;
                  padding-left: 14px;
                  height: 14px;
                  .search-icon {
                    width: 14px;
                    height: 14px;
                    fill: #cfc4be;
                  }
                }
                .search-input-link-people {
                  width: 100% !important;
                  border: none;
                  overflow: hidden;

                  &:focus {
                    box-shadow: none;
                  }
                  &.b-form-input {
                    padding: 0 !important;
                    display: flex;
                    align-items: center !important;
                  }
                }
              }
              .label-title {
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                padding-bottom: 8px;
              }
            }

            &.transfer-open {
              height: 100%;
              border-bottom: none;
            }
          }

          .profile-block {
            padding-top: 8px;
            height: calc(100vh - 370px);

            &:has(:not(.block-no-results)) {
              overflow: auto;
              &::-webkit-scrollbar {
                width: 7px;
              }

              &::-webkit-scrollbar-track {
                margin: 7px 0;
                background: #fff;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
              }
            }

            .block-no-results {
              padding: 8px 0 16px;
              height: 100%;
              .no-results {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
                border-radius: 6px;
                border: 1px solid #cfc4be;

                svg {
                  width: 16px;
                  height: 16px;
                  fill: #998f8a;
                }

                .text {
                  margin: 0;
                  color: #998f8a;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 26px;
                }
              }
            }

            .item {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 8px 16px;
              border-bottom: 1px solid #eee;
              color: #4c4541;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;

              input {
                cursor: pointer;
                width: 18px;
                height: 18px;
                accent-color: #974900;
              }
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }

      .footer-sidebar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
        border-top: 1px solid #cfc4be;
        background: #fff;

        .btn-footer {
          box-shadow: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 5px;

          &:hover {
            box-shadow: none;
          }

          &.btn-transfer {
            border: 1px solid #974900 !important;
            background-color: #974900 !important;
            color: #ffff !important;
            padding: 8px 21px;

            &:hover {
              background-color: #9f5714 !important;
            }

            &:active {
              background-color: #a45f1f !important;
            }
          }
        }
      }
    }
  }
</style>
