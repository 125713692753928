var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"modal-attention-email swal2-container swal2-center swal-conection swal2-backdrop-show"},[_c('div',{staticClass:"swal2-popup swal2-modal swal2-show",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"swal2-header position-relative"},[_c('div',{staticClass:"swal2-title"},[_vm._v(_vm._s(_vm.$t('IntegrationPage.attention')))]),_c('button',{staticClass:"swal2-close d-flex",attrs:{"type":"button","aria-label":"Close this dialog"},on:{"click":function($event){return _vm.UPDATE_MODAL_ATTENTION_EMAIL(false)}}},[_vm._v(" × ")])]),_c('div',{staticClass:"swal2-content"},[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('ProfilesPage.ModalAttentionText'))+" ")]),_c('div',{staticClass:"people-list"},[(
            !!_vm.isToValidate &&
            _vm.peopleList.usersWithoutEmail.find(function (e) { return e.email === '' || e.email === null; })
          )?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.$t('ProfilesPage.ModalAttentionError'))+" ")]):_vm._e(),_c('div',{class:[
            'people-block',
            !!_vm.isToValidate && _vm.combinedUsers.find(function (e) { return e.email === '' || e.email === null; })
              ? 'is-invalid'
              : ''
          ]},_vm._l((_vm.combinedUsers),function(person){return _c('div',{key:person.guid,staticClass:"person-row"},[_c('div',{staticClass:"person-info"},[_c('div',{staticClass:"picture"},[(person.photo)?_c('img',{attrs:{"src":person.photo,"alt":"User photo"}}):_c('AvatarPlaceholder',{attrs:{"size":"40px","text":_vm.picturePlaceholder(person.firstName, person.lastName)}})],1),_c('div',{staticClass:"text-block"},[_c('div',{staticClass:"people-name"},[_vm._v(_vm._s(person.firstName)+" "+_vm._s(person.lastName))]),_c('div',{staticClass:"people-code"},[_vm._v(_vm._s(person.code))])])]),_c('div',{staticClass:"person-email"},[_c('b-form-input',{attrs:{"id":person.guid,"value":person.email,"lazy-formatter":"","formatter":function (e) { return _vm.handleFormatter(e); },"state":_vm.verifyState(person),"placeholder":_vm.$t('SupportPage.Email'),"disabled":_vm.isUserWithEmail(person)},on:{"input":function (e) { return (person.email = e); }}})],1)])}),0)])]),_c('div',{staticClass:"swal2-actions"},[_c('button',{staticClass:"swal2-cancel btn btn-outline-danger ml-1 d-inline-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.UPDATE_MODAL_ATTENTION_EMAIL(false)}}},[_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.cancel'))+" ")]),_c('button',{staticClass:"swal2-confirm btn btn-primary d-inline-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.Save'))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }